/* mobile menu */

body {
  background-color: white;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

p {
  font-size: 12px;
  font-family: "raleway", sans-serif;
}

a {
  text-decoration: none;
  color: blacK;
  font-size: 12px;
}
a:hover {
 text-decoration: underline;
}
a:active {
color: green;
}
  

.small-header {
  margin-top: 50px;
}

.header-container {
  display: flex;
  background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.6),  rgba(0, 0, 0, 0.6)), url(https://i.ibb.co/tmxcrZf/C9-FEE46-C-932-C-45-ED-9900-4-A63576-C5701.jpg);
  background-size: cover;
  height: 500px;
  background-position: center;
}

.header-text {
  position: relative;
  margin: auto;
}

.about {
  padding-bottom: 40px;
}

.about-container {
  position:relative;
  display: grid;
  grid-template-columns: 1fr;
}

.about-text {
  text-align: left;
  width: 60%;
  margin-left: 20%;
}

.client-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 50px;
  align-items: center;
  background-color: #d9d6cf;
  border-radius: 2px;
}


.offer {
  padding: 30px;
}

.offer-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-items: center;
}

.offer-text:hover {
  font-weight: bold;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-bottom: 40px;

}

/* form */

input {
  display: flex;
  width: 300px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 10px;
  background-color: #d9d6cf;;
}

.message-input {
  padding-top: 30px;
  padding-bottom: 30px;
}

.label {
  display: flex;
  font-size: 12px;
}

.submit-button {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  width: 100px;
  color: white;
  padding: 7px;
  margin-top: 15px;
  border-radius: 0.1cm;
  font-size: 10px;
  background-color: #264143;;
}

/*desktop menu */

@media (min-width: 768px) {
  .about-container {
    grid-template-columns: 1fr 1fr;
  }

  .header-text {
    text-align: left;
    position: absolute;
    left: 10%;
    top: 200px;
  }

  .ref-container {
    margin-left: 200px;
    margin-right: 200px;
  }

  .client-container {
    padding: 0px;
  }

  .offer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 30px;
    margin-left: 100px;
    margin-right: 100px;
  }

  .contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-left: 200px;
    margin-right: 200px;
  }
}
