nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid black;
}

.list {
    list-style-type: none;
    display: flex;
}

.items {
    margin-right: 30px;
    color: #433e49;
    cursor: pointer;
    
}

.btn {
    display: none;
    position: absolute;
    background: none;
    border: none;
    right: 10px;
    top: 10px;
    padding: 5px;
    cursor: pointer;;
    color: #000;
    font-size: 18px;
}

.logo {
    display: flex;
    height: 50px;

}

@media screen and (max-width: 500px){

    .list {
        flex-direction: column;
        height: auto;
    }
    .items:nth-child(1){
        margin-top: 50px;
    }
    .items {
        padding: 10px 0;
   
    }
    .btn {
        display: flex;
    }

}